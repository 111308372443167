@font-face {
  font-family: 'Satoshi';
  src: url('./fonts/satoshi-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./fonts/satoshi-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Medium';
  src: url('./fonts/satoshi-medium.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
